<template>
  <div class="person-modify-phone">
    <van-nav-bar title="修改绑定手机" left-arrow @click-left="onClickLeft" />

    <div class="main">
      <div class="modify-content">
        <div class="modify-content-item">
          <span class="tit">原手机号:</span>

          <div class="inputbox">
            <input type="text" readonly v-model="originPhone" />
          </div>
        </div>
        <div class="modify-content-item">
          <span class="tit">新手机号:</span>
          <div class="inputbox">
            <input type="text" v-model="newPhone" @input="onInput"/>
          </div>
          <div class="close" v-show="hasNewPhone" @click="delNewPhone">
            <img src="@image/close.png" alt="" />
          </div>
          <div class="notice">
            <p v-show="phoneError" class="warn">{{ phoneError }}</p>
          </div>
        </div>

        <div class="modify-content-item">
          <span class="tit">验证码:</span>

          <div class="inputbox">
            <input type="number" required @input="setLength" v-model="verif" />
          </div>
          <div class="verif">
            <button :class="isshow==true?'hui-verif':'get-verif'" :disabled="isshow"  v-if="!isSend" @click="showVerifNotice"
              >获取验证码</button
            >
            <span class="get-verif verif-time" v-if="isSend"
              >重新获取{{ verifOutTime }}s</span
            >
          </div>
          <div class="notice">
            <p v-show="verifError" class="warn">{{ verifError }}</p>
          </div>
        </div>
      </div>
      <p class="err">{{ modifyError }}</p>
      <div class="send" @click="submitModify">提交</div>
    </div>
    <van-popup v-model="sendVerif">
      <div class="over">
        <div class="content">
          <p>
            我们将发送验证码短信到<br />+86&nbsp;
            <span class="phoneNumber">{{ originPhone }}</span>
          </p>
        </div>
        <div class="btn-group">
          <span class="cancel" @click="cancel">取消</span>
          <span class="sure" @click="sure">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getcode, updatePhone } from "@api/user.js";
import qs from "qs";

export default {
  data() {
    return {
      //原手机号
      originPhone: this.$store.state.profile.mobile,
      //新手机号
      newPhone: "",
      //发送验证码
      sendVerif: false,
      //是否已经发送
      isSend: false,
      //超时时间
      verifOutTime: 60,
      //验证码
      verif: "",
      //验证码ID
      codeId: "",
      //校验手机号
      phoneError: "",
      //校验验证码
      verifError: "",
      //修改校验
      modifyError: "",
      //控制显示按钮
      isshow:true,
    };
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    //删除新手机输入
    delNewPhone() {
      this.newPhone = "";
    },
    //获取验证码
    showVerifNotice() {
      this.sendVerif = true;
    },
    //限制
    setLength() {
      if (this.verif.length > 6) {
        this.verif = this.verif.slice(0, 6);
      }
    },
    //确认发送验证码
    sure() {
      const params = {
        mobile: this.originPhone,
        type: 1,
      };
      getcode(qs.stringify(params))
        .then((res) => {
          this.codeId = res.data.codeId;
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
      this.sendVerif = false;
      this.isSend = true;
      this.setTime();
    },
    //取消发送验证码
    cancel() {
      this.sendVerif = false;
    },
    // 校验验证码
    setTime() {
      let timer = setInterval(() => {
        if (this.verifOutTime > 0) {
          this.verifOutTime--;
        } else {
          clearInterval(timer);
          this.codeId = "";
          this.verifOutTime = 60;
          this.isSend = false;
        }
      }, 1000);
    },
    onInput(){
       let reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
       console.log(reg.test(this.newPhone))
       if(reg.test(this.newPhone)){
          this.isshow = false
       }
    },
    //提交修改
    submitModify() {
      if (this.newPhone != "" && this.verif != "") {
        if(this.originPhone == this.newPhone){
          this.$toast("两次手机号不能相同！")
          return
        }
        if (!this.codeId) {
          this.modifyError = "请填写正确的验证码!";
          return;
        }
        let reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (!reg.test(this.newPhone)) {
          this.modifyError = "请填写正确的手机号格式!";
        }
        const params = {
          code: this.verif,
          codeId: this.codeId,
          newMobile: this.newPhone,
          originMobile: this.originPhone,
        };
        updatePhone(params)
          .then((res) => {
            if (res.code == 0) {
              this.$store.dispatch("GET_PROFILE");
              setTimeout(() => {
                this.$router.go(-1);
              }, 500);
              this.$toast("修改成功!");
              this.$router.go(-1)
            } else {
              this.modifyError = res.msg;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (this.newPhone == "") {
          this.phoneError = "请输入新手机号";
        }
        if (this.verif == "") {
          this.verifError = "请输入验证码";
        }
      }
    },
  },

  computed: {
    hasNewPhone() {
      if (this.newPhone) {
        return true;
      }
      return false;
    },
  },
  watch: {
    newPhone() {
      this.phoneError = "";
    },
    verif() {
      this.verifError = "";
    },
    modifyError() {
      let timer = setTimeout(() => {
        this.modifyError = "";
        clearTimeout(timer);
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-nav-bar .van-icon {
  color: #333;
}

::v-deep .van-nav-bar__title {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}

::v-deep .van-popup {
  border-radius: 10px;
}

.over {
  width: 285px;
  height: 169px;

  .content {
    height: 115px;
    padding: 50px 60px 0;
    border-bottom: 1px solid #eee;

    p {
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }

  .btn-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 45px;

    span {
      font-size: 15px;
      font-weight: 500;
      color: #999;
    }

    .sure {
      color: #4588ff;
    }
  }
}

.person-modify-phone {
  font-family: PingFang SC;

  .main {
    border-top: 11px solid #f4f4f4;
    padding: 0 30px;

    .modify-content {
      &-item {
        position: relative;
        display: flex;
        align-items: center;
        flex: 1;
        height: 63px;
        border-bottom: 1px solid #eee;

        .tit {
          width: 60px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
        }

        .inputbox {
          width: 200px;
          height: 19px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;

          input {
            width: 100%;
            padding: 0 25px 0 16px;
            font-size: 14px;
            font-weight: 500;
            color: #999999;
          }
        }

        .notice {
          position: absolute;
          left: 75px;
          bottom: 0;
          font-size: 12px;
          color: #999;
          .warn {
            color: red;
          }
        }

        .verif {
          display: flex;
          align-items: center;
          text-align: center;
          .get-verif,
          .get-verif.verif-time {
            font-size: 11px;
            font-weight: 500;
            color: #4588ff;
            background: transparent;
          }
          .hui-verif{
            color:#999 ;
            font-size: 11px;
            font-weight: 500;
            background: transparent;
          }
          .get-verif.verif-time {
            color: #999;
          }
        }

        .close {
          display: flex;
          align-items: center;

          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-item:last-child {
        .inputbox {
          flex: 0 0 180px;
          input {
            border-right: 1px solid #eee;
          }
        }
      }
    }
    .err {
      margin-top: 10px;
      text-align: center;
      font-size: 12px;
      color: red;
    }
    .send {
       width: 302.5px;
          height: 57px;
          margin: 130px auto;
          line-height: 57px;
          text-align: center;
          font-size: 17px;
          font-weight: 500;
          background: #4588ff;
          color: #fff;
          box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
          border-radius: 49px;;
    }
  }
}
</style>
